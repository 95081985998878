import React from 'react';
import Subtitle from 'components/Subpage/Subtitle/Subtitle';
import Text from 'components/Subpage/Text/Text';
import ReactPlayer from 'react-player';

import {
  StyledSectionWrapper,
  StyledImg,
  StyledSectionContentWrapper,
  StyledImgFullWidth,
  StyledImageSectionWrapper,
  StyledTextWrapper,
  StyledImage,
  StyledUrlVideoWrapper,
  StyledVideoWrapper,
} from './SectionListStyles';

const uuidv4 = require('uuid/v4');

const SectionList = ({ sections }) => {
  const renderSection = component => {
    const { strapi_component: componentName } = component;
    switch (componentName) {
      case 'subpage.tekst': {
        const { text, title: componentTitle } = component;
        return (
          <>
            {componentTitle && <Subtitle>{componentTitle}</Subtitle>}
            {text && <Text>{text}</Text>}
          </>
        );
      }
      case 'subpage.tekst-zdjecie': {
        const {
          text,
          title: componentTitle,
          image_align: imageAlign,
          alt_image: altImage,
          image,
        } = component;
        return (
          <>
            <StyledSectionContentWrapper align={imageAlign}>
              <StyledImg
                src={image && process.env.GATSBY_API_URL + image.url}
                alt={altImage && altImage}
              />
              <StyledTextWrapper align={imageAlign}>
                {componentTitle && (
                  <Subtitle align={imageAlign}>{componentTitle}</Subtitle>
                )}
                {text && <Text align={imageAlign}>{text}</Text>}
              </StyledTextWrapper>
            </StyledSectionContentWrapper>
          </>
        );
      }
      case 'subpage.image': {
        const { alternativeText: altImage, image } = component;
        return (
          <>
            <StyledImgFullWidth
              src={image && process.env.GATSBY_API_URL + image.url}
              alt={altImage && altImage}
            />
          </>
        );
      }
      case 'subpage.mosaic': {
        const { image1, image2, image3 } = component;

        return (
          <StyledImageSectionWrapper>
            <StyledImage
              src={image1 && process.env.GATSBY_API_URL + image1.url}
            />
            <StyledImage
              src={image2 && process.env.GATSBY_API_URL + image2.url}
            />
            <StyledImage
              src={image3 && process.env.GATSBY_API_URL + image3.url}
            />
          </StyledImageSectionWrapper>
        );
      }
      case 'video.url-video': {
        const { url_video: urlVideo } = component;
        return (
          <StyledUrlVideoWrapper>
            <ReactPlayer
              playing
              controls
              muted
              loop
              url={urlVideo && urlVideo}
            />
          </StyledUrlVideoWrapper>
        );
      }
      case 'video.local-video': {
        const { video } = component;
        return (
          <StyledVideoWrapper>
            <ReactPlayer
              playing
              controls
              muted
              loop
              url={video && process.env.GATSBY_API_URL + video.url}
              width="100%"
              height="auto"
              display="block"
            />
          </StyledVideoWrapper>
        );
      }
      default:
        return false;
    }
  };

  return sections.map(component => (
    <StyledSectionWrapper key={uuidv4()}>
      {renderSection(component)}
    </StyledSectionWrapper>
  ));
};

export default SectionList;
